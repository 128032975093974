import './styles/global.css'
import { createRoot } from 'react-dom/client'
import Cookies from 'js-cookie'
import { QdsProvider } from '@qasa/qds-ui'
import { ApolloProvider } from '@apollo/client'

import { initializeSentry } from './utils/sentry-client'
import { ContractsApi } from './api/contracts-api'
import { App } from './app'
import { apolloClient } from './graphql/apollo-client'
import { Api } from './api'

initializeSentry()

if (Cookies.get('Access-Token')) {
  Api.init(Cookies.get('Access-Token'))
  ContractsApi.init(Cookies.get('Access-Token'))
}

const domNode = document.getElementById('root')

if (domNode === null) throw new Error('Root container missing in index.html')

const root = createRoot(domNode)

root.render(
  <ApolloProvider client={apolloClient}>
    <QdsProvider locale="en">
      <App />
    </QdsProvider>
  </ApolloProvider>,
)
