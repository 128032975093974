import { graphql } from '../../graphql/__generated__'

export const SEARCH_KANYE_USERS_HOMES = graphql(`
  query searchKanyeUsersHomes(
    $uid: ID!
    $archiveReason: [HomeArchiveReasonEnum!]
    $market: [MarketNameTypeEnum!]
    $sorting: HomeSortingEnum
    $status: [HomeStatusEnum!]
  ) {
    kanyeUser(uid: $uid) {
      kanyeHomes(archiveReason: $archiveReason, market: $market, sorting: $sorting, status: $status) {
        nodes {
          id
          status
          rentalType
          archiveReason
          external
          origin
          externalAdId
          affiliateCode
          roomCount
          squareMeters
          rent
          user {
            uid
            companyName
            professional
            firstName
            private {
              phoneNumber
              idNumber
              familyName
              email
            }
            emailConfirmed
            mainPlatform
            identityConfirmedAt
          }
          updatedAt
          location {
            placeId
            country
            countryCode
            locality
            postalCode
            sublocality
            route
            streetNumber
            formattedAddress
            shortName
            latitude
            longitude
          }
          duration {
            endUfn
            startAsap
            startOptimal
          }
          shortcut {
            id
            currentState
            createdAt
            updatedAt
            agentHome {
              id
            }
            landlordHome {
              id
            }
          }
          recommendedRentNew
          homeReviewData {
            adReviewExplanation
            archivedHomesCount
            publishedHomesCount
          }
        }
      }
    }
  }
`)
