import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Paragraph, Stack, Button, LoadingDots, Link, Heading } from '@qasa/qds-ui'

import { config } from '../../../../config'
import { getFormattedHomeAddress } from '../../utils'
import { usePublishHome, useDenyHome } from '../../../../queries'
import { QasaLink } from '../../../../components/qasa-link'
import { Table2 } from '../../../../components/table/table2'
import { formatDate } from '../../../../utils/date'
import { SearchBar } from '../../../../components/search-bar'
import {
  TabWrapper,
  type HomesInReviewTabProps,
  type KanyeHome,
  type KanyeHomeUser,
} from '../../homes-in-review'

import { AD_REVIEW_DECISION_OPTIONS } from './in-review.data'
import { AdReviewDecisionDialog } from './ad-review-decision-dialog'

export function InReview({
  fetchBtnClicked,
  homesData,
  isLoadingHomes,
  onSelectionChanged,
  refetchHomes,
  resetSelections,
  searchForUserHomes,
  selectedHomes,
  selectedRows,
  setSelectedRows,
  setUserUid,
  userUid,
}: HomesInReviewTabProps) {
  const [adReviewDecisionState, setAdReviewDecisionState] = useState<any>({})
  const [adReviewDecisionSelectedOptions, setAdReviewDecisionSelectedOptions] = useState([])
  const isDialogShowing = Boolean(adReviewDecisionState.options)
  const { mutate: publishHome } = usePublishHome()
  const { mutate: denyHome } = useDenyHome()

  const confirmAction = () =>
    window.confirm('Are you sure? ' + selectedHomes.length + ' homes will be affected')

  const onOpenDialog = (state: any) => setAdReviewDecisionState(state)

  const onCloseDialog = () => {
    setAdReviewDecisionState({})
    setAdReviewDecisionSelectedOptions([])
  }

  const handlePublishHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      const homeIds = selectedHomes.map((home) => home.id)
      homeIds.forEach((homeId) => {
        publishHome(Number(homeId), {
          onSuccess: () => {
            refetchHomes()
            resetSelections()
          },
        })
      })
    }
  }

  const onEditHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      selectedHomes.forEach((home) => {
        const homeId = home.id
        const hostUrl = config[home.user.mainPlatform]
        const url = `${hostUrl}/admin-login-as-user?uid=${homeId}&pathname=/edit-listing/${homeId}/`
        const win = window.open(url, '_blank')
        win?.focus()
      })
    }
  }

  const handleDenyAction = ({ archiveReason, denyReasons }: { archiveReason: any; denyReasons: any }) => {
    if (confirmAction()) {
      selectedHomes.forEach((home) => {
        denyHome(
          { id: Number(home.id), archiveReason, denyReasons },
          {
            onSuccess: async () => {
              refetchHomes()
              resetSelections()
            },
          },
        )
      })
    }
  }

  const actionOptions = [
    {
      label: 'Edit home',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.editHome,
          nextAction: onEditHome,
        }),
      singleItem: true,
    },
    {
      label: 'Publish',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.publish,
          nextAction: handlePublishHome,
          isSingleChoice: true,
        }),
    },
    {
      label: 'Deny',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.deny,
          nextAction: ({ selectedOptions }: { selectedOptions: any }) =>
            handleDenyAction({
              archiveReason: 'review_denied',
              denyReasons: selectedOptions,
            }),
        }),
    },
    {
      label: 'Deny & Verify',
      action: () =>
        handleDenyAction({
          archiveReason: 'needs_verification',
          denyReasons: null,
        }),
    },
    {
      label: 'Deny & Remove user',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.denyAndRemoveUser,
          nextAction: ({ selectedOptions }: { selectedOptions: any }) =>
            handleDenyAction({
              archiveReason: 'suspected_scam',
              denyReasons: selectedOptions,
            }),
        }),
      singleItem: true,
    },
  ]

  const isNotPaginating = fetchBtnClicked === ''

  return (
    <TabWrapper gap="4x">
      {isLoadingHomes && isNotPaginating ? (
        <LoadingDots />
      ) : (
        <>
          <Stack gap="4x" direction="row" alignItems="center">
            <SearchBar
              submit={searchForUserHomes}
              onChange={(uid) => setUserUid(uid)}
              placeholder="User UID"
              value={userUid}
            />
            <Stack gap="2x" direction="row" alignItems="center">
              {actionOptions.map(({ action, label, singleItem }) => (
                <Button
                  style={{ alignSelf: 'center' }}
                  size="sm"
                  variant="tertiary"
                  key={label}
                  disabled={selectedHomes.length === 0 || (singleItem && selectedHomes.length > 1)}
                  onClick={action}
                >
                  {label}
                </Button>
              ))}
            </Stack>
          </Stack>
          <>
            {homesData.length === 0 ? (
              <Heading size="xs">No homes in review right now 🤷‍♀️</Heading>
            ) : (
              <Table2
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                enableRowSelection
                onSelectionChanged={onSelectionChanged}
                rows={homesData}
                getRowKey={(home) => home.id}
                columnsConfig={[
                  {
                    title: 'Rental type',
                    render: (home: KanyeHome) => home.rentalType,
                  },
                  {
                    title: 'Main Platform',
                    render: ({ user }: KanyeHomeUser) => (
                      <Paragraph size="sm"> {user.mainPlatform}</Paragraph>
                    ),
                  },
                  {
                    title: 'Name',
                    render: ({ user }: KanyeHomeUser) => (
                      <Link
                        isExternal
                        as={RouterLink}
                        to={`/user?user=${encodeURIComponent(user.private.email)}`}
                      >
                        {user.professional
                          ? '🏢 ' + user.companyName
                          : `${user.firstName} ${user.private.familyName}`}
                      </Link>
                    ),
                  },
                  {
                    title: 'E-mail',
                    render: ({ user }: KanyeHomeUser) => user.private.email,
                    getCellClassName: ({ user }: KanyeHomeUser) =>
                      user.emailConfirmed ? 'Success' : 'Danger',
                  },
                  {
                    title: 'Bank ID',
                    render: ({ user }: KanyeHomeUser) =>
                      user.identityConfirmedAt
                        ? `Verified: ${formatDate(new Date(user.identityConfirmedAt))}`
                        : 'Not verified',
                    getCellClassName: ({ user }: KanyeHomeUser) =>
                      user.identityConfirmedAt ? 'Success' : 'Danger',
                  },
                  {
                    title: 'Address',
                    render: getFormattedHomeAddress,
                  },
                  {
                    title: 'Rent | Size | Rooms',
                    render: (home: any) => (
                      <QasaLink to={`/home/${home.id}`}>
                        {home.rent} kr | {home.squareMeters} m² | {home.roomCount}
                      </QasaLink>
                    ),
                  },
                ]}
              />
            )}
          </>
          {isDialogShowing && (
            <AdReviewDecisionDialog
              adReviewDecisionState={adReviewDecisionState}
              setSelectedOptions={setAdReviewDecisionSelectedOptions}
              selectedOptions={adReviewDecisionSelectedOptions}
              onClose={onCloseDialog}
              selectedHomes={selectedHomes}
            />
          )}
        </>
      )}
    </TabWrapper>
  )
}
