import styled from '@emotion/styled'
import { InputBase, Stack } from '@qasa/qds-ui'

import { TabsEnum, useAuthorisationContext } from '../../context/authorisation-context'

const InboxSearchWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['3x'],
  paddingBottom: theme.spacing['1x'],
  backgroundColor: theme.colors.core.offWhite,
  width: '100%',
  zIndex: 1,
}))

export function SearchBar() {
  const { selectedTab, searchString, setSearchString } = useAuthorisationContext()

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)

  const placeholder = selectedTab === TabsEnum.Employees ? 'Search employee name' : 'Search group name'
  return (
    <InboxSearchWrapper>
      <InputBase type="search" value={searchString} placeholder={placeholder} onChange={onChangeText} />
    </InboxSearchWrapper>
  )
}
