import styled from '@emotion/styled'
import { Label, Paragraph, Stack } from '@qasa/qds-ui'

import { LoginAsUserButton } from '../user/login-as-user-button'
import { ToggleableIdNumber } from '../../components/toggled-numbers'

import { outputDurationStart } from './utils'
import type { KanyeHome } from './homes-in-review'

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 250,
  borderRadius: theme.radii.xs,
  overflow: 'hidden',
  borderColor: theme.colors.bg.brandTertiary,
  borderWidth: 2,
}))
const HomePreviewListItem = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['3x'],
  ':nth-child(even)': {
    backgroundColor: theme.colors.bg.brandTertiary,
  },
}))

export function HomePreview({ home }: { home: KanyeHome }) {
  const { user } = home || {}
  const { mainPlatform, uid, identityConfirmedAt } = user

  const idNumber = user.private.idNumber
  const idConfirmed = Boolean(identityConfirmedAt)
  const riskExplanation = home.homeReviewData?.adReviewExplanation
  const isShortcut = Boolean(home.shortcut)

  const selectedHomeData = [
    ...(idNumber
      ? [
          {
            Component: () => (
              <ToggleableIdNumber
                isIdConfirmed={idConfirmed}
                idNumber={idNumber}
                mainPlatform={mainPlatform}
                shouldShowRowTitle
              />
            ),
          },
        ]
      : [
          {
            title: 'ID Number: ',
            content: 'n/a',
          },
        ]),
    {
      title: 'Phone number: ',
      content: user.private.phoneNumber,
    },
    {
      title: 'Home ID: ',
      content: home.id,
    },
    {
      title: 'User UID: ',
      content: uid,
    },
    {
      title: 'Published homes: ',
      content: home.homeReviewData.publishedHomesCount,
    },
    {
      title: 'Archived homes: ',
      content: home.homeReviewData.archivedHomesCount,
    },
    ...(Boolean(riskExplanation)
      ? [
          {
            title: 'Risk explanation: ',
            content: riskExplanation,
            hasRiskExplanation: Boolean(riskExplanation),
          },
        ]
      : []),
    ...(isShortcut
      ? [
          {
            title: 'Recommended rent: ',
            content: home.recommendedRentNew,
          },
          {
            title: 'Move in: ',
            content: outputDurationStart(home),
          },
        ]
      : []),

    {
      Component: () => <LoginAsUserButton mainPlatform={mainPlatform} uid={uid} />,
    },
  ]

  return (
    <Wrapper>
      {selectedHomeData.map(({ title, content, Component }, index) => (
        <HomePreviewListItem key={index} wrap="wrap" gap="3x">
          {title && <Label>{title}</Label>}
          {content && <Paragraph as="span">{content}</Paragraph>}
          {Component && <Component />}
        </HomePreviewListItem>
      ))}
    </Wrapper>
  )
}
