import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Heading, LoadingDots, Stack, Link } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'

import { Table2 } from '../../components/table/table2'
import { QasaLink } from '../../components/qasa-link'
import { getFormattedHomeAddress } from '../homes-in-review/utils'
import { HomePreview } from '../homes-in-review/home-preview'
import { KANYE_HOMES } from '../homes-in-review/kanye-homes.gql'
import type { KanyeHome, PageInfo } from '../../graphql/__generated__/graphql'
import { HomeSortingEnum, HomeStatusEnum, MarketNameTypeEnum } from '../../graphql/__generated__/graphql'
import { formatDate } from '../../utils/date'
import { POSTS_PER_PAGE } from '../homes-in-review/tabs/constants'
import { PaginationButtons } from '../homes-in-review/pagination-buttons'

import { SettingsCard } from './settings-card'
import { ShortcutTransitionActions } from './shortcut-transition-actions'

const ShorcutWrapper = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing['4x'],
  width: '100vw',
}))
const TopSection = Stack
const SettingsWrapper = styled(Stack)({
  maxWidth: 400,
})

export const updatedOutputTimeSinceShortcutCreation = (home: KanyeHome) => {
  const createdAt = home?.shortcut?.createdAt ?? ''
  return new Date(createdAt).toLocaleString()
}

export function Shortcut() {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedHomes, setSelectedHomes] = useState<KanyeHome[]>([])
  const [homes, setHomes] = useState<any[]>([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null)
  const [fetchMoreBtnClicked, setFetchMoreBtnClicked] = useState('')
  const [highlightedHome, highlightHome] = useState<KanyeHome | null>(null)
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const {
    refetch: refetchShortcutHomes,
    fetchMore,
    loading: isLoadingHomes,
  } = useQuery(KANYE_HOMES, {
    variables: {
      market: MarketNameTypeEnum.Sweden,
      sorting: HomeSortingEnum.UpdatedAsc,
      status: [HomeStatusEnum.PendingShortcut],
      first: POSTS_PER_PAGE,
    },
    onCompleted: (data) => {
      setIsLoading(true)
      const shortcutData = data?.kanyeHomes
      if (shortcutData?.__typename === 'KanyeHomeConnection') {
        const { nodes, totalCount, pageInfo } = shortcutData
        setHomes(nodes)
        setCount(totalCount)
        setPageInfo(pageInfo)
      }
      setIsLoading(false)
      setFetchMoreBtnClicked('')
    },
    onError: () => {
      setIsLoading(false)
      setFetchMoreBtnClicked('')
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const canFetchMore = pageInfo?.endCursor && pageInfo?.hasNextPage && !isLoadingHomes

  const fetchMoreInReviewHomes = () => {
    setFetchMoreBtnClicked('more')
    setCurrentPage((page) => page + 1)
    const endCursor = pageInfo?.endCursor
    if (canFetchMore && endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
        updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
      })
    }
  }

  const canFetchLess = pageInfo?.startCursor && pageInfo?.hasPreviousPage && !isLoadingHomes

  const fetchLessInReviewHomes = () => {
    setFetchMoreBtnClicked('less')
    setCurrentPage((page) => page - 1)
    const startCursor = pageInfo?.startCursor
    if (canFetchLess && startCursor) {
      fetchMore({
        variables: {
          before: startCursor,
          first: undefined,
          last: POSTS_PER_PAGE,
        },
        updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
      })
    }
  }

  const onSelectionChanged = (selection: number[]) => {
    setSelectedHomes(selection.map((indexOfSelectedHome: number) => homes[indexOfSelectedHome]))
    const highlightedHome = selection.length === 1 ? homes[selection[0]] : null
    highlightHome(highlightedHome)
  }

  const onComplete = () => {
    setSelectedRows([])
    highlightHome(null)
    refetchShortcutHomes()
  }

  const shouldShowPaginationBtns = pageInfo?.hasNextPage || pageInfo?.hasPreviousPage

  return (
    <ShorcutWrapper gap="4x">
      <Heading>Shortcut</Heading>
      <SettingsWrapper>
        <SettingsCard />
      </SettingsWrapper>
      <TopSection direction="row">
        <ShortcutTransitionActions selectedHomes={selectedHomes} onComplete={() => onComplete()} />
      </TopSection>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <>
          {homes.length === 0 ? (
            <Heading size="xs">No shortcut candidates right now 🤷‍♀️</Heading>
          ) : (
            <Table2
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              enableRowSelection
              onSelectionChanged={onSelectionChanged}
              rows={homes}
              getRowKey={(home) => home.id}
              columnsConfig={[
                {
                  title: 'Name',
                  render: ({ user }: { user: any }) => (
                    <Link
                      isExternal
                      as={RouterLink}
                      to={`/user?user=${encodeURIComponent(user.private.email)}`}
                    >
                      {user.professional
                        ? '🏢 ' + user.companyName
                        : `${user.firstName} ${user.private.familyName}`}
                    </Link>
                  ),
                },
                {
                  title: 'E-mail',
                  render: ({ user }: { user: any }) => user.private.email,
                  getCellClassName: ({ user }: { user: any }) => (user.emailConfirmed ? 'Success' : 'Danger'),
                },
                {
                  title: 'Bank ID',
                  render: ({ user }: { user: any }) =>
                    user.identityConfirmedAt
                      ? `Verified: ${formatDate(new Date(user.identityConfirmedAt))}`
                      : 'Not verified',
                  getCellClassName: ({ user }: { user: any }) =>
                    user.identityConfirmedAt ? 'Success' : 'Danger',
                },
                {
                  title: 'Address',
                  render: getFormattedHomeAddress,
                },
                {
                  title: 'Rent | Size | Rooms',
                  render: (home: any) => (
                    <QasaLink to={`/home/${home.id}`}>
                      {home.rent} kr | {home.squareMeters} m² | {home.roomCount}
                    </QasaLink>
                  ),
                },
                {
                  title: 'Shorcut status',
                  render: (home: KanyeHome) => home?.shortcut?.currentState ?? 'n/a',
                },
                {
                  title: 'Candidate created at',
                  render: updatedOutputTimeSinceShortcutCreation,
                },
              ]}
            />
          )}
        </>
      )}
      {shouldShowPaginationBtns && (
        <PaginationButtons
          metadata={{
            totalHits: count,
            currentPage,
            totalPages: Math.ceil(count / POSTS_PER_PAGE),
          }}
          handleFetchLess={fetchLessInReviewHomes}
          isLoadingLess={isLoadingHomes && fetchMoreBtnClicked === 'less'}
          canFetchLess={Boolean(canFetchLess)}
          handleFetchMore={fetchMoreInReviewHomes}
          canFetchMore={Boolean(canFetchMore)}
          isLoadingMore={isLoadingHomes && fetchMoreBtnClicked === 'more'}
        />
      )}
      {highlightedHome && <HomePreview home={highlightedHome} />}
    </ShorcutWrapper>
  )
}
