import { Link as RouterLink } from 'react-router-dom'
import { Button, Heading, Link, LoadingDots, Stack } from '@qasa/qds-ui'

import { SearchBar } from '../../../components/search-bar'
import { QasaLink } from '../../../components/qasa-link'
import { Table2 } from '../../../components/table/table2'
import { getFormattedHomeAddress } from '../utils'
import { usePublishHome } from '../../../queries'
import { formatDate } from '../../../utils/date'
import {
  TabWrapper,
  type HomesInReviewTabProps,
  type KanyeHome,
  type KanyeHomeUser,
} from '../homes-in-review'

export function DeniedHomes({
  fetchBtnClicked,
  homesData,
  isLoadingHomes,
  onSelectionChanged,
  refetchHomes,
  resetSelections,
  searchForUserHomes,
  selectedHomes,
  selectedRows,
  setSelectedRows,
  setUserUid,
  userUid,
}: HomesInReviewTabProps) {
  const { mutate: publishHome, isLoading: isPublishHomeLoading } = usePublishHome()

  const confirmAction = () =>
    window.confirm('Are you sure? ' + selectedHomes.length + ' homes will be affected')

  const handlePublishHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      const homeIds = selectedHomes.map((home: KanyeHome) => home.id)
      homeIds.forEach((homeId) => {
        publishHome(Number(homeId), {
          onSuccess: () => {
            refetchHomes()
            resetSelections()
          },
        })
      })
    }
  }

  const isNotPaginating = fetchBtnClicked === ''

  return (
    <TabWrapper gap="4x">
      {isLoadingHomes && isNotPaginating ? (
        <LoadingDots />
      ) : (
        <>
          <Stack direction="row" gap="4x" alignItems="center">
            <SearchBar
              submit={searchForUserHomes}
              onChange={(uid) => setUserUid(uid)}
              placeholder="User UID"
              value={userUid}
            />
            <Button
              style={{ alignSelf: 'center' }}
              variant="tertiary"
              disabled={selectedHomes.length === 0}
              onClick={handlePublishHome}
              isLoading={isPublishHomeLoading}
            >
              Publish
            </Button>
          </Stack>
          <>
            {homesData.length === 0 ? (
              <Heading size="xs">No denied homes right now 🤷‍♀️</Heading>
            ) : (
              <Table2
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                enableRowSelection
                onSelectionChanged={onSelectionChanged}
                rows={homesData}
                getRowKey={(home) => home.id}
                columnsConfig={[
                  {
                    title: 'Rental type ',
                    render: (home: any) => home.rentalType,
                  },
                  {
                    title: 'Name',
                    render: ({ user }: KanyeHomeUser) => (
                      <Link
                        isExternal
                        as={RouterLink}
                        to={`/user?user=${encodeURIComponent(user.private.email)}`}
                      >
                        {user.professional
                          ? '🏢 ' + user.companyName
                          : `${user.firstName} ${user.private.familyName}`}
                      </Link>
                    ),
                  },
                  {
                    title: 'E-mail',
                    render: ({ user }: KanyeHomeUser) => user.private.email,
                    getCellClassName: ({ user }: KanyeHomeUser) =>
                      user.emailConfirmed ? 'Success' : 'Danger',
                  },
                  {
                    title: 'Bank ID',
                    render: ({ user }: KanyeHomeUser) =>
                      user.identityConfirmedAt
                        ? `Verified: ${formatDate(new Date(user.identityConfirmedAt))}`
                        : 'Not verified',
                    getCellClassName: ({ user }: KanyeHomeUser) =>
                      user.identityConfirmedAt ? 'Success' : 'Danger',
                  },
                  {
                    title: 'Address',
                    render: getFormattedHomeAddress,
                  },
                  {
                    title: 'Rent | Size | Rooms',
                    render: (home: KanyeHome) => (
                      <QasaLink to={`/home/${home.id}`}>
                        {home.rent} kr | {home.squareMeters} m² | {home.roomCount}
                      </QasaLink>
                    ),
                  },
                  {
                    title: 'Deny reason',
                    render: (home: KanyeHome) => home.archiveReason,
                  },
                ]}
              />
            )}
          </>
        </>
      )}
    </TabWrapper>
  )
}
