import { MarketNameTypeEnum } from '../../../graphql/__generated__/graphql'

export const POSTS_PER_PAGE = 15

// employee todo: remove after auth system switch
export const platformEnumToMarketNameTypeEnumMap = {
  blocket: MarketNameTypeEnum.Sweden,
  dotcom: MarketNameTypeEnum.Sweden,
  homeq: MarketNameTypeEnum.Sweden,
  qasa: MarketNameTypeEnum.Sweden,
  finn: MarketNameTypeEnum.Norway,
  qasa_finland: MarketNameTypeEnum.Finland,
  qasa_france: MarketNameTypeEnum.France,
}

export enum HomesInReviewTabs {
  InReview = 'inReview',
  DeniedHomes = 'deniedHomes',
  NeedsVerificaiton = 'needsVerificaiton',
  LatestPublished = 'latestPublished',
}
