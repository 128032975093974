import { Link as RouterLink } from 'react-router-dom'
import { Button, LoadingDots, Stack, Link, Heading } from '@qasa/qds-ui'

import { QasaLink } from '../../../components/qasa-link'
import { SearchBar } from '../../../components/search-bar'
import { Table2 } from '../../../components/table/table2'
import { config } from '../../../config'
import { usePublishHome, useDenyHome } from '../../../queries'
import { getFormattedHomeAddress } from '../utils'
import { formatDate } from '../../../utils/date'
import {
  TabWrapper,
  type HomesInReviewTabProps,
  type KanyeHome,
  type KanyeHomeUser,
} from '../homes-in-review'

export function NeedsVerification({
  fetchBtnClicked,
  homesData,
  isLoadingHomes,
  onSelectionChanged,
  refetchHomes,
  resetSelections,
  searchForUserHomes,
  selectedHomes,
  selectedRows,
  setSelectedRows,
  setUserUid,
  userUid,
}: HomesInReviewTabProps) {
  const { mutate: publishHome, isLoading: isPublishHomeLoading } = usePublishHome()
  const { mutate: denyHome, isLoading: isDenyHomeLoading } = useDenyHome()

  const confirmAction = () =>
    window.confirm('Are you sure? ' + selectedHomes.length + ' homes will be affected')

  const handleEditHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      selectedHomes.forEach((home: KanyeHome) => {
        const win = window.open(`${config[home.user.mainPlatform]}/listings/${home.id}/edit`, '_blank')
        win?.focus()
      })
    }
  }

  const handlePublishHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      const homeIds = selectedHomes.map((home: KanyeHome) => home.id)
      homeIds.forEach((homeId) => {
        publishHome(Number(homeId), {
          onSuccess: () => {
            resetSelections()
            refetchHomes()
          },
        })
      })
    }
  }

  const handleDenyHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      const homeIds = selectedHomes.map((home: KanyeHome) => home.id)
      homeIds.forEach((homeId) => {
        denyHome(
          { id: Number(homeId), archiveReason: 'review_denied' },
          {
            onSuccess: () => {
              resetSelections()
              refetchHomes()
            },
          },
        )
      })
    }
  }

  const actions = [
    {
      label: 'Edit home',
      action: handleEditHome,
      singleItem: true,
    },
    {
      label: 'Publish',
      action: handlePublishHome,
      singleItem: false,
      isLoading: isPublishHomeLoading,
    },
    {
      label: 'Deny',
      action: handleDenyHome,
      singleItem: false,
      isLoading: isDenyHomeLoading,
    },
  ]

  const isNotPaginating = fetchBtnClicked === ''

  return (
    <TabWrapper gap="4x">
      {isLoadingHomes && isNotPaginating ? (
        <LoadingDots />
      ) : (
        <>
          <Stack gap="4x" direction="row">
            <SearchBar
              submit={searchForUserHomes}
              onChange={(uid) => setUserUid(uid)}
              placeholder="User UID"
              value={userUid}
            />
            <Stack direction="row" gap="2x">
              {actions.map(({ label, singleItem, action, isLoading }) => (
                <Button
                  style={{ alignSelf: 'center' }}
                  variant="tertiary"
                  key={label}
                  disabled={selectedHomes.length === 0 || (singleItem && selectedHomes.length > 1)}
                  onClick={action}
                  isLoading={isLoading}
                >
                  {label}
                </Button>
              ))}
            </Stack>
          </Stack>
          {homesData.length === 0 ? (
            <Heading size="xs">No homes need verification right now 🤷‍♀️</Heading>
          ) : (
            <Table2
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              enableRowSelection
              onSelectionChanged={onSelectionChanged}
              rows={homesData}
              getRowKey={(home) => home.id}
              columnsConfig={[
                {
                  title: 'Rental type',
                  render: (home: KanyeHome) => home.rentalType,
                },
                {
                  title: 'Name',
                  render: ({ user }: KanyeHomeUser) => (
                    <Link
                      isExternal
                      as={RouterLink}
                      to={`/user?user=${encodeURIComponent(user.private.email)}`}
                    >
                      {user.professional
                        ? '🏢 ' + user.companyName
                        : `${user.firstName} ${user.private.familyName}`}
                    </Link>
                  ),
                },
                {
                  title: 'E-mail',
                  render: ({ user }: KanyeHomeUser) => user.private.email,
                  getCellClassName: ({ user }: KanyeHomeUser) => (user.emailConfirmed ? 'Success' : 'Danger'),
                },
                {
                  title: 'Bank ID',
                  render: ({ user }: KanyeHomeUser) =>
                    user.identityConfirmedAt
                      ? `Verified: ${formatDate(new Date(user.identityConfirmedAt))}`
                      : 'Not verified',
                  getCellClassName: ({ user }: KanyeHomeUser) =>
                    user.identityConfirmedAt ? 'Success' : 'Danger',
                },
                {
                  title: 'Address',
                  render: getFormattedHomeAddress,
                },
                {
                  title: 'Rent | Size | Rooms',
                  render: (home: any) => (
                    <QasaLink to={`/home/${home.id}`}>
                      {home.rent} kr | {home.squareMeters} m² | {home.roomCount}
                    </QasaLink>
                  ),
                },
                {
                  title: 'Deny reason',
                  render: (home: KanyeHome) => home.archiveReason,
                },
              ]}
            />
          )}
        </>
      )}
    </TabWrapper>
  )
}
