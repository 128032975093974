import { graphql } from '../../graphql/__generated__'

export const KANYE_HOMES = graphql(`
  query KanyeHomes(
    $archiveReason: [HomeArchiveReasonEnum!]
    $market: [MarketNameTypeEnum!]
    $sorting: HomeSortingEnum
    $status: [HomeStatusEnum!]
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    kanyeHomes(
      archiveReason: $archiveReason
      market: $market
      sorting: $sorting
      status: $status
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ... on AuthorizationError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
        message
      }
      ... on KanyeHomeConnection {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        nodes {
          id
          status
          rentalType
          archiveReason
          external
          origin
          externalAdId
          affiliateCode
          roomCount
          squareMeters
          rent
          user {
            uid
            companyName
            professional
            firstName
            private {
              phoneNumber
              idNumber
              familyName
              email
            }
            emailConfirmed
            mainPlatform
            identityConfirmedAt
          }
          updatedAt
          location {
            placeId
            country
            countryCode
            locality
            postalCode
            sublocality
            route
            streetNumber
            formattedAddress
            shortName
            latitude
            longitude
          }
          duration {
            endUfn
            startAsap
            startOptimal
          }
          shortcut {
            id
            currentState
            createdAt
            updatedAt
            agentHome {
              id
            }
            landlordHome {
              id
            }
          }
          recommendedRentNew
          homeReviewData {
            adReviewExplanation
            archivedHomesCount
            publishedHomesCount
          }
        }
      }
    }
  }
`)
