import type { KanyeHome } from './homes-in-review'

export const getFormattedHomeAddress = (home: KanyeHome) => {
  const { formattedAddress, route, streetNumber, postalCode, locality } = home.location

  if (formattedAddress) {
    return formattedAddress
  } else {
    return `${route ?? ''} ${streetNumber ?? ''}, ${postalCode ?? ''} ${locality ?? ''}`
  }
}

export const outputDurationStart = (home: KanyeHome) => {
  const { startAsap, startOptimal } = home.duration || {}
  const start = startAsap ? 'Asap' : startOptimal ? new Date(startOptimal).toLocaleDateString() : 'n/a'
  return start
}
