import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import qasaLogo from 'src/assets/qasa.svg'
import { Button, Stack, Avatar, Label, IconButton, ArrowRightIcon, ArrowLeftIcon } from '@qasa/qds-ui'

import { useLocalStorage } from '../../hooks/use-local-storage'
import { useAuthContext } from '../../context/auth-context'

const LogoWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing['2x'],
}))
const CollapseButtonWrapper = styled.div({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(50%, -50%)',
})
const CollapseButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.colors.border.default}`,
}))
const NavWrapper = styled(Stack)<{ isCollapsed: boolean }>(({ theme, isCollapsed }) => ({
  height: '100%',
  zIndex: theme.zIndices.docked,
  padding: theme.spacing['2x'],
  boxShadow: theme.shadows.md,
  width: 180,
  minWidth: 180,
  marginLeft: isCollapsed ? -160 : 0,
  transition: 'margin 0.3s',
  position: 'relative',
}))
const NavContent = styled(Stack)({
  flex: 1,
})
const LogoImage = styled.img({
  width: 120,
})
const Link = styled(NavLink)(({ theme }) => ({
  ...theme.typography.body.md,
  padding: theme.spacing['2x'],
  borderRadius: theme.radii.md,
  ':hover': {
    background: theme.colors.core.gray20,
  },
}))

const NAV_LINKS = [
  {
    to: '/user',
    label: 'Users',
  },
  {
    to: '/homes-in-review',
    label: 'Homes in review',
  },
  {
    to: '/search-areas',
    label: 'Search areas',
  },
  {
    to: '/suspicious-users',
    label: 'Suspicious Users',
  },
  {
    to: '/shortcut',
    label: 'Shortcut',
  },
]

const OVERDUE_PAYMENTS_LINK = {
  to: '/overdue-payments',
  label: 'Overdue payments',
}

export function Navbar({ hasAuthorisationAccess }: { hasAuthorisationAccess: boolean }) {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useLocalStorage('navbarCollapsed', false)
  const { logOut, currentEmployee, authBody } = useAuthContext()
  const profilePictureUrl = currentEmployee?.profilePictureUrl || authBody?.profilePicture?.url

  const firstName = currentEmployee?.name || authBody?.firstName

  const handleToggleNavbarCollapsed = () => setIsNavbarCollapsed((prevCollapsed) => !prevCollapsed)

  return (
    <NavWrapper
      gap="8x"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      isCollapsed={Boolean(isNavbarCollapsed)}
    >
      <CollapseButtonWrapper>
        <CollapseButton
          label=""
          icon={isNavbarCollapsed ? ArrowRightIcon : ArrowLeftIcon}
          variant={'ghost'}
          size={'xs'}
          onClick={handleToggleNavbarCollapsed}
        />
      </CollapseButtonWrapper>
      <NavLink to="/">
        <LogoWrapper>
          <LogoImage src={qasaLogo} alt="Logo" />
        </LogoWrapper>
      </NavLink>
      <NavContent justifyContent="space-between" alignItems="center">
        <Stack gap="1x" direction="column">
          {NAV_LINKS.map(({ to, label }) => (
            <Link activeStyle={{ fontWeight: 'bold' }} key={label} to={to}>
              {label}
            </Link>
          ))}
          <Link
            activeStyle={{ fontWeight: 'bold' }}
            key={OVERDUE_PAYMENTS_LINK.label}
            to={OVERDUE_PAYMENTS_LINK.to}
          >
            {OVERDUE_PAYMENTS_LINK.label}
          </Link>
          {hasAuthorisationAccess && (
            <Link activeStyle={{ fontWeight: 'bold' }} key="Authorisation" to="/authorisation">
              Authorisation
            </Link>
          )}
        </Stack>
        <Stack gap="2x" style={{ width: '100%' }}>
          <Stack gap="2x" direction="row" alignItems="center">
            <Avatar size="md" src={profilePictureUrl ?? ''} />
            {firstName && <Label>{firstName}</Label>}
          </Stack>
          <Button variant="tertiary" onClick={logOut}>
            Logout
          </Button>
        </Stack>
      </NavContent>
    </NavWrapper>
  )
}
