import { Link as RouterLink } from 'react-router-dom'
import { Stack, Button, Paragraph, LoadingDots, Link, Heading } from '@qasa/qds-ui'

import { Table2 } from '../../../components/table/table2'
import { QasaLink } from '../../../components/qasa-link'
import { SearchBar } from '../../../components/search-bar'
import { getFormattedHomeAddress } from '../utils'
import { useHomePublishedOnBlocket, useArchiveHome } from '../../../queries'
import { EmployeePermissionActionEnum } from '../../../graphql/__generated__/graphql'
import { formatDate } from '../../../utils/date'
import { useAuthContext } from '../../../context/auth-context'
import {
  TabWrapper,
  type HomesInReviewTabProps,
  type KanyeHome,
  type KanyeHomeUser,
} from '../homes-in-review'

export function LatestPublished({
  fetchBtnClicked,
  homesData,
  isLoadingHomes,
  onSelectionChanged,
  refetchHomes,
  resetSelections,
  searchForUserHomes,
  selectedHomes,
  selectedRows,
  setSelectedRows,
  setUserUid,
  userUid,
}: HomesInReviewTabProps) {
  const { permissions } = useAuthContext()

  const marketAccess = permissions.filter((permission) =>
    permission.actions.includes(EmployeePermissionActionEnum.MarketAccess),
  )

  const hasSwedishMarketAccess = Boolean(marketAccess.find((market) => market.objectType === 'sweden'))

  const { mutate: archiveHome, isLoading: isArchiveHomeLoading } = useArchiveHome()
  const { mutate: publishHomeOnBlocket, isLoading: isPublishHomeOnBlocketLoading } =
    useHomePublishedOnBlocket()

  const confirmAction = () =>
    window.confirm('Are you sure? ' + selectedHomes.length + ' homes will be affected')

  const onArchiveHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      selectedHomes.forEach((home: KanyeHome) => {
        archiveHome(
          { id: Number(home.id), archiveReason: 'review_denied' },
          {
            onSuccess: () => {
              refetchHomes()
              resetSelections()
            },
          },
        )
      })
    }
  }

  const onBlocketPublish = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      selectedHomes.forEach((home: KanyeHome) => {
        publishHomeOnBlocket(
          { id: String(home.id) },
          {
            onSuccess: () => {
              refetchHomes()
              resetSelections()
            },
          },
        )
      })
    }
  }

  const actions = [
    {
      label: 'Archive',
      action: onArchiveHome,
      singleItem: false,
      isLoading: isArchiveHomeLoading,
    },
    {
      label: 'Publish on Blocket',
      action: onBlocketPublish,
      singleItem: false,
      isLoading: isPublishHomeOnBlocketLoading,
    },
  ]

  const isNotPaginating = fetchBtnClicked === ''

  return (
    <TabWrapper gap="4x">
      {isLoadingHomes && isNotPaginating ? (
        <LoadingDots />
      ) : (
        <>
          <Stack gap="4x" direction="row">
            <SearchBar
              submit={searchForUserHomes}
              onChange={(uid) => setUserUid(uid)}
              placeholder="User UID"
              value={userUid}
            />
            <Stack gap="2x" direction="row">
              {actions.map(({ label, action, singleItem, isLoading }) => (
                <Button
                  style={{ alignSelf: 'center' }}
                  variant="tertiary"
                  key={label}
                  disabled={selectedHomes.length === 0 || (singleItem && selectedHomes.length > 1)}
                  onClick={action}
                  isLoading={isLoading}
                >
                  {label}
                </Button>
              ))}
            </Stack>
          </Stack>
          {homesData.length === 0 ? (
            <Heading size="xs">No latest published homes right now 🤷‍♀️</Heading>
          ) : (
            <Table2
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              enableRowSelection
              onSelectionChanged={onSelectionChanged}
              rows={homesData}
              getRowKey={(home) => home.id}
              columnsConfig={[
                {
                  title: 'Name',
                  render: ({ user }: KanyeHomeUser) => (
                    <Link
                      isExternal
                      as={RouterLink}
                      to={`/user?user=${encodeURIComponent(user.private.email)}`}
                    >
                      {user.professional
                        ? '🏢 ' + user.companyName
                        : `${user.firstName} ${user.private.familyName}`}
                    </Link>
                  ),
                },
                {
                  title: 'E-mail',
                  render: ({ user }: KanyeHomeUser) => user.private.email,
                  getCellClassName: ({ user }: KanyeHomeUser) => (user.emailConfirmed ? 'Success' : 'Danger'),
                },
                {
                  title: 'Bank ID',
                  render: ({ user }: KanyeHomeUser) =>
                    user.identityConfirmedAt
                      ? `Verified: ${formatDate(new Date(user.identityConfirmedAt))}`
                      : 'Not verified',
                  getCellClassName: ({ user }: KanyeHomeUser) =>
                    user.identityConfirmedAt ? 'Success' : 'Danger',
                },
                {
                  title: 'Address',
                  render: getFormattedHomeAddress,
                },
                {
                  title: 'Rent | Size | Rooms',
                  render: (home: any) => (
                    <QasaLink to={`/home/${home.id}`}>
                      {home.rent} kr | {home.squareMeters} m² | {home.roomCount}
                    </QasaLink>
                  ),
                },
                ...(hasSwedishMarketAccess
                  ? [
                      {
                        title: 'Blocket Ad',
                        render: (home: any) => {
                          const externalAdId = home.externalAdId
                          return externalAdId ? (
                            <Link href={'http://blocket.se/vi/' + externalAdId}>{externalAdId}</Link>
                          ) : (
                            <Paragraph size="sm">{'n/a'}</Paragraph>
                          )
                        },
                      },
                    ]
                  : []),
              ]}
            />
          )}
        </>
      )}
    </TabWrapper>
  )
}
