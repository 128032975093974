import { Button, Paragraph, Stack } from '@qasa/qds-ui'
import { useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'

import { Dialog } from '../../components/_core/dialog'
import type { ShortcutRejectionReasonEnum, ShortcutStatesEnum } from '../../graphql/__generated__/graphql'
import { RadioGroup } from '../../components/_core/radio-group'
import { notifyFailure, notifySuccess } from '../../utils'

import { TRANSITION_SHORTCUT_HOME } from './shortcut.gql'
import { SHORTCUT_DECISION_OPTIONS_MAP, type ShortcutDecisionOption } from './shortcut.data'

const ButtonWrapper = styled.div({ display: 'flex', justifyContent: 'center' })

type TransitionDialogProps = {
  dialogState: ShortcutDecisionOption
  setDialogState: React.Dispatch<React.SetStateAction<ShortcutDecisionOption | null>>
  selectedHomes: any[]
  onComplete: VoidFunction
}

function TransitionDialog({ dialogState, setDialogState, selectedHomes, onComplete }: TransitionDialogProps) {
  const [rejectionReason, setRejectionReason] = useState<ShortcutRejectionReasonEnum | null>(null)
  const { title, description, reason, toState, actionLabel, successMessage } =
    SHORTCUT_DECISION_OPTIONS_MAP[dialogState]

  const [transitionShortcutHome, { loading: isLoading }] = useMutation(TRANSITION_SHORTCUT_HOME, {
    onCompleted: (data) => {
      if (data.transitionShortcut?.errors) {
        notifyFailure('Something went wrong')
      } else {
        notifySuccess(successMessage)
        onComplete()
        setDialogState(null)
      }
    },
    onError: (error) => {
      notifyFailure(error.message)
    },
  })

  const handleTransitionShortcutHome = (toState: ShortcutStatesEnum) => {
    selectedHomes.forEach((home) => {
      const shortcutId = home.shortcut.id

      transitionShortcutHome({
        variables: { transitionShortcutInput: { toState, reason: rejectionReason, shortcutId } },
      })
    })
  }

  return (
    <Dialog isOpen onOpenChange={() => setDialogState(null)}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{title}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="4x">
            <Paragraph>{description}</Paragraph>
            {reason && (
              <RadioGroup
                name={reason.title}
                label={reason.title}
                onChange={setRejectionReason}
                value={rejectionReason ?? ''}
              >
                {reason.options.map(({ label, value }) => (
                  <RadioGroup.Item key={value} value={value} label={label} />
                ))}
              </RadioGroup>
            )}
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <ButtonWrapper>
            <Button
              isLoading={isLoading}
              onClick={() => {
                handleTransitionShortcutHome(toState)
              }}
            >
              {actionLabel}
            </Button>
          </ButtonWrapper>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

type ShortcutTransitionActionsProps = Pick<TransitionDialogProps, 'selectedHomes' | 'onComplete'>

export function ShortcutTransitionActions({ selectedHomes, onComplete }: ShortcutTransitionActionsProps) {
  const [dialogState, setDialogState] = useState<ShortcutDecisionOption | null>(null)
  const { isAnySelectedHomePaused, hasNoSelectedHomes } = useMemo(() => {
    return {
      isAnySelectedHomePaused: selectedHomes.some((home) => home.shortcut.currentState === 'paused'),
      hasNoSelectedHomes: selectedHomes.length === 0,
    }
  }, [selectedHomes])

  return (
    <>
      <Stack direction="row" gap="1x">
        <Button
          size="sm"
          variant="tertiary"
          onClick={() => {
            setDialogState('pauseInShortcut')
          }}
          isDisabled={hasNoSelectedHomes || isAnySelectedHomePaused}
        >
          Pause in Shortcut
        </Button>
        <Button
          size="sm"
          variant="tertiary"
          onClick={() => {
            setDialogState('removeFromShortcut')
          }}
          isDisabled={hasNoSelectedHomes}
        >
          Remove from Shortcut
        </Button>
        <Button
          size="sm"
          variant="tertiary"
          onClick={() => {
            setDialogState('enableShortcut')
          }}
          isDisabled={hasNoSelectedHomes}
        >
          Enable Shortcut
        </Button>
      </Stack>
      {dialogState && (
        <TransitionDialog
          dialogState={dialogState}
          setDialogState={setDialogState}
          selectedHomes={selectedHomes}
          onComplete={onComplete}
        />
      )}
    </>
  )
}
