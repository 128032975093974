import styled from '@emotion/styled'
import { Button, TextField, Stack, Heading } from '@qasa/qds-ui'
import QasaLogo from 'src/assets/qasa.svg'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

import { Alert } from '../../components/_core/alert'
import { axiosInstance, loginAdmin } from '../../api/api-service'
import { ContractsApi } from '../../api/contracts-api'
import { ReactComponent as GoogleLogo } from '../../assets/google_logo.svg'
import { config } from '../../config'
import { type Employee } from '../../context/auth-context'
import { Api } from '../../api'

function popupWindow(url: string, win: any, w: number, h: number) {
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2
  return win.open(
    url,
    '',
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`,
  )
}

const PageWrapper = styled(Stack)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  background: theme.colors.bg.default,
}))
const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['2x'],
  width: theme.sizes[384],
}))
const StyledForm = styled.form({ width: '100%' })
const LogoImage = styled.img(({ theme }) => ({
  width: theme.sizes[128],
}))

const DEFAULT_VALUES = {
  email: '',
  password: '',
}

type Inputs = {
  email: string
  password: string
}

type LoginProps = {
  updateAccessToken: (token: string) => void
  loginPath: string
  currentEmployee: Employee | undefined
}
export function Login({ updateAccessToken, loginPath, currentEmployee }: LoginProps) {
  const [hasAuthError, setHasAuthError] = useState(false)

  const handleInitApis = (token: string) => {
    Api.init(token)
    updateAccessToken(token)
    ContractsApi.init(token)
    axiosInstance.defaults.headers.common['access-token'] = token
  }

  const { mutate: login, isLoading } = useMutation({
    mutationFn: loginAdmin,
    onError: (error) => {
      setHasAuthError(true)
      reportError(error)
      Sentry.captureException(error)
    },
    onSuccess: ({ data }) => {
      const token = data.accessToken
      handleInitApis(token)
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: DEFAULT_VALUES })

  const email = watch('email')
  const password = watch('password')

  const handleLogin = () => {
    login({ email, password })
  }

  const loginEmployee = (isLoggingIn: boolean) => {
    let url = `${config.api}/admin/sessions/logout`

    if (isLoggingIn) {
      url = `${config.api}${loginPath}`
    }

    // const authPopup =
    popupWindow(url, window, 600, 400)

    /*
      I wanted to autoclose the dialog based on the content shown inside the window.
      atm i'm getting cross origin errors and can't access the child window information
      in order to see this inner text
    */
    // authPopup.onload = () => {
    //   const childWindow = authPopup.window
    //   if (childWindow) {
    //     const innerText = childWindow.document.documentElement.innerText
    //     console.log('loginEmployee ~ innerText:', innerText)
    //     if (innerText.includes('Authentication Successful')) {
    //       authPopup.close()
    //       window.location.reload()
    //     }
    //   }
    // }

    // temporary solution - refetch the kanye current user
    // authPopup.onunload = () => {
    //   new Promise((resolve) => {
    //     fetchKanyeCurrentUser()
    //     resolve(null)
    //   }).then(() => authPopup.close())
    // }
  }

  return (
    <PageWrapper gap="12x" alignItems="center" justifyContent="center">
      <LogoImage src={QasaLogo} alt="Logo" />
      <Wrapper gap="3x" justifyContent="center" alignItems="center">
        <StyledForm onSubmit={handleSubmit(handleLogin)}>
          <Stack gap="8x">
            <Heading size="lg">Login</Heading>
            <Stack gap="4x">
              <TextField
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Invalid email format',
                  },
                })}
                label="Email"
                placeholder="admin@qasa.se"
                type="email"
                isInvalid={Boolean(errors.email)}
                errorMessage={errors.email?.message}
              />
              <TextField
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password should be at least 6 characters',
                  },
                })}
                label="Password"
                placeholder="********"
                type="password"
                isInvalid={Boolean(errors.password)}
                errorMessage={errors.password?.message}
              />
              <Button isLoading={isLoading} variant="primary" type="submit">
                Log in
              </Button>
              {!currentEmployee && loginPath ? (
                <Button
                  iconLeft={GoogleLogo}
                  isDisabled={!loginPath}
                  variant="primary"
                  // onClick={() => loginEmployee(true)}
                  onClick={() =>
                    window.open(`${config.api}${loginPath}?redirect_to=${window.location.href}`, '_self')
                  }
                >
                  Log in with Google
                </Button>
              ) : (
                <Button iconLeft={GoogleLogo} variant="primary" onClick={() => loginEmployee(false)}>
                  Logout from Google
                </Button>
              )}
            </Stack>
            {hasAuthError && (
              <Alert
                variant="error"
                title="Invalid  email or password"
                description={'Check that the email address and password are correct.'}
              />
            )}
          </Stack>
        </StyledForm>
      </Wrapper>
    </PageWrapper>
  )
}
