import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router'
import { Stack } from '@qasa/qds-ui'
import uniq from 'lodash/uniq'

import { Tabs } from '../../components/tabs/tabs'
import { EmployeePermissionActionEnum } from '../../graphql/__generated__/graphql'
import type {
  KanyeHomesQuery,
  MarketNameTypeEnum,
  SearchKanyeUsersHomesQuery,
} from '../../graphql/__generated__/graphql'
import { useAuthContext } from '../../context/auth-context'

import { InReview, DeniedHomes, NeedsVerification, LatestPublished } from './tabs'
import { HomePreview } from './home-preview'
import { HomesInReviewTabs, platformEnumToMarketNameTypeEnumMap, POSTS_PER_PAGE } from './tabs/constants'
import type { SearchForUserHomesLazyQuery } from './use-homes-in-review'
import { useHomesInReview } from './use-homes-in-review'
import { PaginationButtons } from './pagination-buttons'

export const TabWrapper = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing['4x'],
  width: '100vw',
}))

export type KanyeHome = Extract<
  KanyeHomesQuery['kanyeHomes'],
  { __typename: 'KanyeHomeConnection' }
>['nodes'][number]
export type SearchKanyeUserHome = SearchKanyeUsersHomesQuery['kanyeUser']['kanyeHomes']['nodes'][number]

export type KanyeHomeUser = Pick<KanyeHome, 'user'>

export type HomesInReviewTabProps = {
  fetchBtnClicked: string
  homesData: KanyeHome[]
  isLoadingHomes: boolean
  onSelectionChanged: (selection: number[]) => void
  refetchHomes: () => void
  resetSelections: () => void
  searchForUserHomes: SearchForUserHomesLazyQuery
  selectedHomes: KanyeHome[]
  selectedRows: number[]
  setSelectedRows: Dispatch<SetStateAction<number[]>>
  setUserUid: Dispatch<SetStateAction<string>>
  userUid: string
}

const ContentWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  overflow: 'scroll',
  paddingBottom: theme.spacing['4x'],
  alignSelf: 'flex-start',
}))
const HomePreviewSection = styled.div(({ theme }) => ({
  height: '100%',
  position: 'sticky',
  bottom: 0,
  marginBottom: theme.spacing['4x'],
}))

const TAB_COMPONENT = {
  [HomesInReviewTabs.InReview]: InReview,
  [HomesInReviewTabs.DeniedHomes]: DeniedHomes,
  [HomesInReviewTabs.NeedsVerificaiton]: NeedsVerification,
  [HomesInReviewTabs.LatestPublished]: LatestPublished,
}

const PATH_TAB_MAP = {
  '/homes-in-review': HomesInReviewTabs.InReview,
  '/homes-in-review/denied-homes': HomesInReviewTabs.DeniedHomes,
  '/homes-in-review/needs-verification': HomesInReviewTabs.NeedsVerificaiton,
  '/homes-in-review/latest-published': HomesInReviewTabs.LatestPublished,
} as const

type TabMapKeys = keyof typeof PATH_TAB_MAP
type TabMapValues = (typeof PATH_TAB_MAP)[TabMapKeys]

export function HomesInReview() {
  const { authBody, permissions } = useAuthContext()
  const [userUid, setUserUid] = useState('')
  const [activeTab, setActiveTab] = useState(HomesInReviewTabs.InReview)
  const [highlightedHome, setHighlightedHome] = useState<KanyeHome | null>(null)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [selectedHomes, setSelectedHomes] = useState<KanyeHome[]>([])

  // employee todo: remove this after auth system switch
  const adminPlatformsToMarketNamesArray =
    authBody?.private?.adminPlatforms?.map((platform) => platformEnumToMarketNameTypeEnumMap[platform]) ?? []

  const marketAccessArray = permissions
    .map((permission) => {
      if (permission.actions.includes(EmployeePermissionActionEnum.MarketAccess)) {
        return permission.objectType
      }
    })
    .filter(Boolean) as MarketNameTypeEnum[]

  const marketsArray = authBody ? uniq(adminPlatformsToMarketNamesArray) : marketAccessArray

  const {
    canFetchLess,
    canFetchMore,
    clearPageInfo,
    count,
    currentPage,
    fetchBtnClicked,
    fetchLess,
    fetchMore,
    homesData,
    isLoadingHomes,
    pageInfo,
    refetchHomes,
    searchedUserHomes,
    searchForUserHomes,
  } = useHomesInReview({ tab: activeTab, marketsArray, userUid })

  const resetSelections = () => {
    setSelectedRows([])
    highlightHome(null)
    setSelectedHomes([])
  }

  const onSelectionChanged = (selection: number[]) => {
    const data = searchedUserHomes || homesData
    setSelectedHomes(selection.map((indexOfSelectedHome: number) => data[indexOfSelectedHome]))
    const highlightedHome = selection.length === 1 ? data[selection[0]] : null
    highlightHome(highlightedHome)
  }

  const { pathname, search } = location
  const { push } = useHistory()

  const changeTab = ({ path }: { path: string }) => {
    setHighlightedHome(null)
    clearPageInfo()
    push(`/homes-in-review${path}${search}`)
  }

  useEffect(() => {
    setActiveTab(PATH_TAB_MAP[pathname as TabMapKeys])
  }, [pathname])

  if (!activeTab) return null

  const tabs = [
    {
      id: HomesInReviewTabs.InReview,
      label: 'In review',
      onClick: () => changeTab({ path: '' }),
    },
    {
      id: HomesInReviewTabs.DeniedHomes,
      label: 'Denied homes',
      onClick: () => changeTab({ path: '/denied-homes' }),
    },
    {
      id: HomesInReviewTabs.NeedsVerificaiton,
      label: 'Needs verification',
      onClick: () => changeTab({ path: '/needs-verification' }),
    },
    {
      id: HomesInReviewTabs.LatestPublished,
      label: 'Latest published',
      onClick: () => changeTab({ path: '/latest-published' }),
    },
  ]

  const highlightHome = (home: KanyeHome | null) => setHighlightedHome(home)

  const ActiveTab = TAB_COMPONENT[activeTab as TabMapValues]

  const { hasNextPage, hasPreviousPage } = pageInfo || {}

  return (
    <>
      <Tabs tabs={tabs} activeTab={activeTab} />
      <Stack direction="row" alignItems="flex-end">
        <ContentWrapper>
          <Stack gap="4x">
            <ActiveTab
              fetchBtnClicked={fetchBtnClicked}
              homesData={searchedUserHomes || homesData}
              isLoadingHomes={isLoadingHomes}
              onSelectionChanged={onSelectionChanged}
              refetchHomes={refetchHomes}
              resetSelections={resetSelections}
              searchForUserHomes={searchForUserHomes}
              selectedHomes={selectedHomes}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setUserUid={setUserUid}
              userUid={userUid}
            />
            {/* // note: hide pagination when searching for a users homes, pagination has not been added yet for searchKanyeUsersHomes query */}
            {Boolean(hasNextPage || hasPreviousPage) && !searchedUserHomes && (
              <PaginationButtons
                metadata={{
                  totalHits: count,
                  currentPage,
                  totalPages: Math.ceil(count / POSTS_PER_PAGE),
                }}
                handleFetchLess={() => {
                  resetSelections()
                  fetchLess()
                }}
                isLoadingLess={isLoadingHomes && fetchBtnClicked === 'less'}
                canFetchLess={Boolean(canFetchLess)}
                handleFetchMore={() => {
                  resetSelections()
                  fetchMore()
                }}
                canFetchMore={Boolean(canFetchMore)}
                isLoadingMore={isLoadingHomes && fetchBtnClicked === 'more'}
              />
            )}
          </Stack>
        </ContentWrapper>
        {highlightedHome && (
          <HomePreviewSection>
            <HomePreview home={highlightedHome} />
          </HomePreviewSection>
        )}
      </Stack>
    </>
  )
}
