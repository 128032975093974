import { Button, Divider, Stack } from '@qasa/qds-ui'
import Cookies from 'js-cookie'
import styled from '@emotion/styled'

import { ContractStatusEnum, FranceInsuranceRequestStateEnum } from '../../../graphql/__generated__/graphql'
import { UpdateScriveId } from '../contracts/components/update-scrive-id'
import { UpdateDepositMonth } from '../contracts/update-deposit-month'
import type { KanyeUserContractType } from '../contracts/contracts.gql'
import { TransitionMilaDocumentsState } from '../contracts/components/transition-mila-documents-state/transition-mila-documents-state'

import { TransitionMilaInsuranceRequest } from './transition-mila-insurance-request'
import { DocumentRequestSelector } from './document-requests/document-request-selector'

const Column = styled(Stack)({ flex: 1 })

export const approvedMilaRequestStates = [
  FranceInsuranceRequestStateEnum.Approved,
  FranceInsuranceRequestStateEnum.AwaitingContractSigning,
]

type UserContractCTAsComponentProps = {
  contract: KanyeUserContractType
}
export function UserContractCTAsFrance({ contract }: UserContractCTAsComponentProps) {
  const isDraft = contract.status === ContractStatusEnum.Draft

  const rentalInsuranceMilaRequest = contract.rentalInsuranceMilaRequest
  const milaRequestCurrentState = rentalInsuranceMilaRequest?.currentState
  const milaId = rentalInsuranceMilaRequest?.milaId
  const documentRequests = rentalInsuranceMilaRequest?.documentRequests

  const hasUploadedDocuments = documentRequests ? documentRequests.length > 0 : false

  const isAwaitingMilaDecision =
    milaId &&
    hasUploadedDocuments &&
    !approvedMilaRequestStates.includes(milaRequestCurrentState as LegitimateAny)

  const hasNoActiveActions = !isDraft && !rentalInsuranceMilaRequest && !hasUploadedDocuments

  if (hasNoActiveActions) {
    return null
  }

  const isDev = process.env.NODE_ENV === 'development'

  return (
    <Stack gap="6x" direction="row">
      <Column gap="4x" divider={<Divider />}>
        <form method="post" target="_blank" action={`${process.env.REACT_APP_API_URL}/finance/sessions`}>
          <input type="hidden" name="token" value={Cookies.get('Access-Token')} />
          <input type="hidden" name="redirect_to" value={`/finance/contracts/${contract.id}`} />
          <Button variant="tertiary" type="submit" size="md" style={{ width: '100%' }}>
            {'Finance dashboard'}
          </Button>
        </form>
        {rentalInsuranceMilaRequest && (
          <TransitionMilaInsuranceRequest
            contractId={contract.id}
            rentalInsuranceMilaRequest={rentalInsuranceMilaRequest}
          />
        )}
        {hasUploadedDocuments && <DocumentRequestSelector documentRequests={documentRequests} />}
      </Column>
      <Column gap="4x" divider={<Divider />}>
        {isDraft && (
          <>
            <UpdateScriveId contractId={contract.id} />
            {/* We need to assert that we have the correct marketDetails type */}
            {contract.marketDetails?.__typename === 'FranceMarketDetails' && (
              <UpdateDepositMonth
                currentDepositMonths={contract.marketDetails.depositMonths}
                contractId={contract.id}
              />
            )}
          </>
        )}
        {isAwaitingMilaDecision && isDev && <TransitionMilaDocumentsState milaId={milaId} />}
      </Column>
    </Stack>
  )
}
